import API_URL from "../config";
import axios from "axios";

import { socket } from "./../socket";
import { getCookie } from './../utils/cookies';

export const signIn = async (email, password) => {
  try {
    const userObject = {
      email: email,
      password: password,
    };

    const res = await axios.post(`${API_URL}/api/V2/superAdmin/signIn`, userObject);

    // socket.emit("user_login", {
    //     user_id: res.data.userInfo._id
    // });

    return res.data;
  } catch (err) {
    // socket.emit("get_online_users");
    throw err;
  }
};

export const isAuthenticatedApi = async() => {
  try {
      const token = getCookie("T6hVzR7c0g3maM3F")
      const iv = getCookie("02hfCmUqpk66w4uG")
      const id = getCookie("3F2Mb2pJ1YRro5Z4")

      const option = {
          headers: {
              Authorization: `Bearer ${token}`
          }
      }

      const res = await axios.get(`${API_URL}/api/V2/superAdmin/profile/${id}/${iv}`, option);

      if(res.data)
          return true
  }
  catch (err) {
      return false
  }
}

export const signOut = async () => {
  try {
    const id = localStorage.getItem("id");
    socket.emit("logOut", id);
  } catch (err) {
    throw err;
  }
};
