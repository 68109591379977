/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const horizontalBarChartData2 = {
  labels: [
    `BOUDCHART AGADIR - 20 Août 2024`,
    `ELGRANDETOTO en Live concert à Agadir 'Twenty-Seven Tour'`,
    `IMGHRANE GUEST : NAJAT AATABOU - HAJIB - LAARBI IMGHRANE - HAMID EL KASRI`,
    `BOUDCHART`,
    `Soirée AJYAL 'IZENZAREN, IMGHRAN, JUBANTUJA'`,
    `LHOLI FESTIVAL - AGADIR`
  ],
  datasets: [
    {
      label: "%",
      color: "dark",
      data: [100, 20, 12, 60, 20, 15],
    },
  ],
};

export default horizontalBarChartData2;
