import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const addNewCategoryApi = async(formValues) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            name: formValues.name
        }

        const res = await axios.post(`${API_URL}/api/V2/eventCategory/create/${id}/${iv}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateCategoryApi = async(formValues, categoryID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            name: formValues.name
        }

        const res = await axios.put(`${API_URL}/api/V2/eventCategory/update/${id}/${iv}/${categoryID}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllCategoriesApi = async () => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/eventCategory/read/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteOneCategoryApi = async (categoryID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/eventCategory/delete/${id}/${iv}/${categoryID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}
