/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";

import MDButton from "components/MDButton";
import { getAllEventApi } from "api/events";

const getTableData = async () => {
  try {
    const AllEventsData = await getAllEventApi()
    console.log("AllEventsData", AllEventsData)
  }
  catch (err) {
    console.log(err)
  }
}

const handleEditClick = (row) => {
  console.log("handleEditClick", row);
};

const dataTableData = {
  columns: [
    {
      Header: "Nom d'événement",
      accessor: "product",
      Cell: ({ value }) => {
        const [name, data] = value;

        return (
          <DefaultCell
            value={typeof value === "string" ? value : name}
            suffix={data.suffix || false}
          />
        );
      },
    },
    {
      Header: "Date début",
      accessor: "fromDate",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Date fin",
      accessor: "toDate",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => {
        let status;

        if (value === "paid") {
          status = <StatusCell icon="done" color="success" status="Terminé" />;
        } else if (value === "refunded") {
          status = <StatusCell icon="replay" color="dark" status="En cours" />;
        } else {
          status = <StatusCell icon="close" color="error" status="Annulée" />;
        }

        return status;
      },
    },
    {
      Header: "Total des tickets",
      accessor: "customer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      //   Cell: ({ value: [name, data] }) => (
      //     <CustomerCell image={data.image} color={data.color || "dark"} name={name} />
      //   ),
    },
    {
      Header: "Chiffre d'affaires",
      accessor: "revenue",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ value }) => (
        <MDButton variant="text" color="warning" onClick={() => handleEditClick(value)}>
          <StatusCell icon="edit" color="warning" status="" />
        </MDButton>
      ),
    },
  ],

  rows: [
    {
      fromDate: "1 Nov, 10:20 AM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "100",
      product: "Nike Sport V2",
      revenue: "MAD 140,20",
      actions: "10000",
    },
    {
      fromDate: "1 Nov, 10:53 AM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "200",
      product: "Valvet T-shirt",
      revenue: "MAD 42,00",
      actions: "",
    },
    {
      fromDate: "1 Nov, 11:13 AM",
      toDate: "1 Nov, 10:20 AM",
      status: "refunded",
      customer: "500",
      product: ["Leather Wallet", { suffix: "+1 more" }],
      revenue: "MAD 25,50",
      actions: "",
    },
    {
      fromDate: "1 Nov, 12:20 PM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "620",
      product: "Bracelet Onu-Lino",
      revenue: "MAD 19,40",
      actions: "",
    },
    {
      fromDate: "1 Nov, 1:40 PM",
      toDate: "1 Nov, 10:20 AM",
      status: "canceled",
      customer: "550",
      product: ["Phone Case Pink", { suffix: "x 2" }],
      revenue: "MAD 44,90",
      actions: "",
    },
    {
      fromDate: "1 Nov, 2:19 PM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "620",
      product: "Backpack Niver",
      revenue: "MAD 112,50",
      actions: "",
    },
    {
      fromDate: "1 Nov, 3:42 AM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "780",
      product: "Adidas Vio",
      revenue: "MAD 200,00",
      actions: "",
    },
    {
      fromDate: "2 Nov, 9:32 AM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "230",
      product: "Airpods 2 Gen",
      revenue: "MAD 350,00",
      actions: "",
    },
    {
      fromDate: "2 Nov, 10:14 AM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "450",
      product: "Bracelet Warret",
      revenue: "MAD 15,00",
      actions: "",
    },
    {
      fromDate: "2 Nov, 10:14 AM",
      toDate: "1 Nov, 10:20 AM",
      status: "refunded",
      customer: "987",
      product: ["Watter Bottle India", { suffix: "x 3" }],
      revenue: "MAD 25,00",
      actions: "",
    },
    {
      fromDate: "2 Nov, 3:12 PM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "890",
      product: "Kitchen Gadgets",
      revenue: "MAD 164,90",
      actions: "",
    },
    {
      fromDate: "2 Nov, 5:12 PM",
      toDate: "1 Nov, 10:20 AM",
      status: "paid",
      customer: "235",
      product: "Office Papers",
      revenue: "MAD 23,90",
      actions: "",
    },
  ],
};

export default dataTableData;
