import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

// formik components
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Box, CircularProgress, Dialog } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { addNewSiegeApi, updateSiegeApi } from "api/sieges";
import { v4 as uuidv4 } from 'uuid';

function EventSieges({ openEventSiege, closeEventSiege, refreshData, isEdite, openModalItemData, newSiegeData }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const validationSchema = Yup.object().shape({
    eventSiege: Yup.string().required("Saisser le nom de nouvelle siège"),
    eventPrice: Yup.number().required("Saisser le prix de nouvelle siège"),
    numberOfPlaces: Yup.number().required("Saisser le nombre des places de nouvelle siège"),
  });

  useEffect(() => {
    getInitialValue()
  }, [openModalItemData, isEdite])

  const getInitialValue = () => {
    try {
      if(isEdite) {
        
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleAddNewEventSiege = async (value) => {
    try {
      const formValues = {
        name: value.eventSiege,
        price: value.eventPrice,
        numberOfPlaces: value.numberOfPlaces,
        id: uuidv4()
      }

      newSiegeData(formValues)

      refreshData()
      closeEventSiege();
    } catch (err) {
      console.log(err)
    }
  };

  const handleUpdateEventSiege = async (value) => {
    try {
      const formValues = {
        name: value.eventSiege,
        price: value.eventPrice,
        numberOfPlaces: value.numberOfPlaces
      }

      await updateSiegeApi(formValues, openModalItemData._id)
      Swal.fire({
        title: "Ajoutée",
        text: "",
        icon: "success"
      });

      refreshData()
      closeEventSiege();
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Dialog open={openEventSiege} onClose={closeEventSiege} fullWidth maxWidth="sm">
        <MDBox>
          <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  eventSiege: isEdite ? openModalItemData?.name : "",
                  eventPrice: isEdite ? openModalItemData?.price : "",
                  numberOfPlaces: isEdite ? openModalItemData?.numberOfPlaces : "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  isEdite ? handleUpdateEventSiege(values) : handleAddNewEventSiege(values)
                }}
              >
                <Form autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <MDBox>
                        <MDBox mb={1.625}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} mb={1.2}>
                              <Field name="eventSiege">
                                {({ field, meta }) => (
                                  <>
                                    <MDInput
                                      {...field}
                                      label="Siège"
                                      size="large"
                                      fullWidth
                                      error={meta.error}
                                      success={!meta.error && meta.touched}
                                    />
                                    {meta.touched && meta.error && (
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        {meta.error}
                                      </MDTypography>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} mb={1.2}>
                              <Field name="eventPrice">
                                {({ field, meta }) => (
                                  <>
                                    <MDInput
                                      {...field}
                                      type="number"
                                      label="Prix"
                                      size="large"
                                      fullWidth
                                      error={meta.error}
                                      success={!meta.error && meta.touched}
                                    />
                                    {meta.touched && meta.error && (
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        {meta.error}
                                      </MDTypography>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} mb={1.2}>
                              <Field name="numberOfPlaces">
                                {({ field, meta }) => (
                                  <>
                                    <MDInput
                                      {...field}
                                      type="number"
                                      label="Nombre des places"
                                      size="large"
                                      fullWidth
                                      error={meta.error}
                                      success={!meta.error && meta.touched}
                                    />
                                    {meta.touched && meta.error && (
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        {meta.error}
                                      </MDTypography>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Grid>
                          </Grid>
                        </MDBox>
                        {isLoaded ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <MDButton type="submit" variant="gradient" color="dark">
                            {isEdite ? "Modifier" : "Ajouter"}
                          </MDButton>
                        )}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              </Formik>
            </Grid>
          </Grid>
        </MDBox>
      </Dialog>
    </div>
  );
}

EventSieges.propTypes = {
  openEventSiege: PropTypes.bool.isRequired,
  closeEventSiege: PropTypes.func.isRequired,
};

export default EventSieges;
