import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const getAllMediaApi = async () => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/ourMedia/read/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneMediaApi = async (mediaID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/ourMedia/readOne/${id}/${iv}/${mediaID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const addNewMediaImgsApi = async (formObjectData) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const formData = new FormData();

        formData.append("mediaName", formObjectData?.mediaName);
        formData.append("mediaDescription", formObjectData?.mediaDescription);
        formData.append("mediaVideo", formObjectData?.mediaVideo);
        formData.append("mediaImg", formObjectData?.mediaImg);

        const res = await axios.post(`${API_URL}/api/V2/ourMedia/create/${id}/${iv}`, formData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateMediaImgsApi = async (formObjectData, mediaID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const formData = new FormData();

        formData.append("mediaName", formObjectData?.mediaName);
        formData.append("mediaDescription", formObjectData?.mediaDescription);
        formData.append("mediaVideo", formObjectData?.mediaVideo);
        formData.append("mediaImg", formObjectData?.mediaImg);

        const res = await axios.put(`${API_URL}/api/V2/ourMedia/update/${id}/${iv}/${mediaID}`, formData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteMediaApi = async (mediaID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/ourMedia/delete/${id}/${iv}/${mediaID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const mediaStatusApi = async (mediaID, status) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/V2/ourMedia/status/${id}/${iv}/${mediaID}/${status ? "active" : "inactive"}`, {}, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const mediaSliderStatusApi = async (mediaID, status) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/V2/ourMedia/sliderStatus/${id}/${iv}/${mediaID}/${status ? "active" : "inactive"}`, {}, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const mediaWebSiteStatusApi = async (mediaID, status) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/V2/ourMedia/webStatus/${id}/${iv}/${mediaID}/${status ? "active" : "inactive"}`, {}, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}
