/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { getAllEventApi, getOneEventSiegeApi } from "api/events";

function FormFields({ formValues }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));

  useEffect(() => {
    getInitialData();
  }, []);

  const [eventData, setEventData] = useState([]);
  const [siegeData, setSiegeData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSiege, setSelectedSiege] = useState(null);

  const [formDataValues, setFormDataValues] = useState({
    eventId: "",
    coponCode: "",
    reduction: 0,
    fromDate: "",
    toDate: "",
    typeOfCopon: "montant"
  });

  const [siegeFormDataValues, setSiegeFormDataValues] = useState([
    {
      siegeUId: "",
      numbersOfUse: 0
    },
  ])

  const getInitialData = async () => {
    try {
      const eventResponse = await getAllEventApi();
      const eventDataArray = eventResponse.map((el) => ({
        title: el.eventTitle,
        id: el._id,
      }));
      setEventData(eventDataArray);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);

    // Immediately pass the updated form data
    formValues(newFormDataValues);
  };

  const handleAutoCompleteEventChange = async (event, newValue) => {
    try {
      handleClearForm();

      const newFormDataValues = {
        ...formDataValues,
        eventId: newValue ? newValue?.id : "",
      };

      setFormDataValues(newFormDataValues);
      setSelectedEvent(newValue);

      // get one event siege
      if (newValue?.id) {
        const oneEventDetails = await getOneEventSiegeApi(newValue?.id);
        setSiegeData(oneEventDetails.eventSiege);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAutoCompleteSiegeChange = async (event, newValue) => {
    setFormDataValues({
      ...formDataValues,
      siege: newValue ? newValue?.id : "",
    });
    setSelectedSiege(newValue);
  };

  const handleAddNewSiege = () => {
    setSiegeFormDataValues([
      ...siegeFormDataValues,
      {
        siegeUId: "",
        numbersOfUse: 0,
      },
    ]);
  }
  const handleDeleteSiege = (index) => {
    setSiegeFormDataValues(siegeFormDataValues.filter((_, i) => i !== index));
  }

  const handleClearForm = () => {
    setFormDataValues({
      eventId: "",
      coponCode: "",
      reduction: 0,
      fromDate: "",
      toDate: "",
      typeOfCopon: "montant"
    });
    setSelectedEvent(null); // Reset event Autocomplete
    setSelectedSiege(null); // Reset siege Autocomplete
    setSiegeData([]); // Clear siege options
  };

  return (
    <>
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5">Information</MDTypography>
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  // multiple
                  value={selectedEvent}
                  options={eventData}
                  getOptionLabel={(option) => option?.title || ""}
                  onChange={handleAutoCompleteEventChange}
                  renderInput={(params) => <FormField {...params} label="Evénement" />}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Code coupon"
                  name="coponCode"
                  value={formDataValues.coponCode}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Type (montant/pourcentage)
                </MDTypography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="typeOfCopon-label"
                    name="typeOfCopon"
                    value={formDataValues.typeOfCopon}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel value="montant" control={<Radio />} label="Montant" />
                    <FormControlLabel value="pourcentage" control={<Radio />} label="Pourcentage" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="number"
                  label="Réduction"
                  name="reduction"
                  value={formDataValues.reduction}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="date"
                  label="Date de début"
                  name="fromDate"
                  value={formDataValues.fromDate}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="date"
                  label="Date de fin"
                  name="toDate"
                  value={formDataValues.toDate}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>

      <Card style={{ marginTop: "1rem" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">Sièges</MDTypography>
          {
            siegeFormDataValues.map((el, index) => (
              <MDBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      value={selectedSiege}
                      options={siegeData}
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={handleAutoCompleteSiegeChange}
                      renderInput={(params) => <FormField {...params} label="Siège" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      type="number"
                      label="Nombre d'utilisations"
                      name="promoVideo"
                      value={formDataValues.promoVideo}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/* <MDButton color="success" variant="text" ></MDButton> */}
                    {
                      siegeFormDataValues.length - 1 == index ?
                      <Icon color="success" fontSize="medium" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "10%" }} onClick={() => handleAddNewSiege()}>add</Icon> :
                      <Icon color="error" fontSize="medium" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "10%" }} onClick={() => handleDeleteSiege(index)}>delete</Icon>
                    }
                  </Grid>
                </Grid>
              </MDBox>
            ))
          }
        </MDBox>
      </Card>
    </>
  );
}

FormFields.propTypes = {
  formValues: PropTypes.string.isRequired,
};

export default FormFields;
