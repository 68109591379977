/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function FormFields({ formValues }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));

  const [formDataValues, setFormDataValues] = useState({
    typeOfCompany: "1"
  });

  const [selectedRadioValue, setSelectedRadioValue] = useState("montant");

  const handleRadioChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);

    // Immediately pass the updated form data
    formValues(newFormDataValues);
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Informations</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Nom"
                name="eventTitle"
                value={formDataValues.eventTitle}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Prénom"
                name="eventTitle"
                value={formDataValues.eventTitle}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="CIN (Carte d'Identité Nationale)"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="typeOfCompany-label"
                    name="typeOfCompany"
                    value={formDataValues.typeOfCompany}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Société" />
                    <FormControlLabel value="2" control={<Radio />} label="Association" />
                    <FormControlLabel value="3" control={<Radio />} label="Autoentrepreneur" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            {
              formDataValues.typeOfCompany == "1" ?
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Nom de la société"
                  name="promoVideo"
                  value={formDataValues.promoVideo}
                  onChange={handleInputChange}
                />
              </Grid> :
              formDataValues.typeOfCompany == "2" ?
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Nom de l'association"
                  name="promoVideo"
                  value={formDataValues.promoVideo}
                  onChange={handleInputChange}
                />
              </Grid> :
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Nom de l'autoentrepreneur"
                  name="promoVideo"
                  value={formDataValues.promoVideo}
                  onChange={handleInputChange}
                />
              </Grid>
            }
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="ICE (Identifiant Commun d'Entreprise)"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="IF (Identifiant Fiscal)"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Compte bancaire"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Pseudo du producteur"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Email"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Mot de passe"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Confirmer le mot de passe"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

FormFields.propTypes = {
  formValues: PropTypes.string.isRequired,
};

export default FormFields;
