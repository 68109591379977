/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sales dashboard components
import ProductCell from "layouts/dashboards/sales/components/ProductCell";
import RefundsCell from "layouts/dashboards/sales/components/RefundsCell";
import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";

// Images
import nikeV22 from "assets/images/ecommerce/blue-shoe.jpeg";
import businessKit from "assets/images/ecommerce/black-mug.jpeg";
import blackChair from "assets/images/ecommerce/black-chair.jpeg";
import wirelessCharger from "assets/images/ecommerce/bang-sound.jpeg";
import tripKit from "assets/images/ecommerce/photo-tools.jpeg";

const dataTableData = {
  columns: [
    { Header: "événement", accessor: "product" }, // width: "50%"
    { Header: "nombre total de places", accessor: "value" },
    { Header: "places vendues", accessor: "adsSpent", align: "center" },
    { Header: "places restantes", accessor: "refunds", align: "center" },

    { Header: "invitation", accessor: "invitationPlaces", align: "center" },
    { Header: "places en ligne", accessor: "onlinePlaces", align: "center" },
    { Header: "places hors ligne", accessor: "offlinePlaces", align: "center" },
    { Header: "chiffre d'affaires", accessor: "sumOfPrice", align: "center" },
  ],

  rows: [
    {
      product: <ProductCell image={nikeV22} name=" BOUDCHART AGADIR - 20 Août 2024 " place={"théâtre de verdure"} />,
      value: <DefaultCell>992</DefaultCell>,
      adsSpent: <DefaultCell>500</DefaultCell>,
      refunds: <DefaultCell>500</DefaultCell>, // <RefundsCell value={13} icon={{ color: "success", name: "keyboard_arrow_up" }} />
      invitationPlaces: <DefaultCell>500</DefaultCell>,
      onlinePlaces: <DefaultCell>500</DefaultCell>,
      offlinePlaces: <DefaultCell>500</DefaultCell>,
      sumOfPrice: <DefaultCell>4200 MAD</DefaultCell>,
    },
    {
      product: <ProductCell image={businessKit} name=" ELGRANDETOTO en Live concert à Agadir 'Twenty-Seven Tour' " place={"théâtre de verdure"} />,
      value: <DefaultCell>19</DefaultCell>,
      adsSpent: <DefaultCell>100</DefaultCell>,
      refunds: <DefaultCell>500</DefaultCell>,
      invitationPlaces: <DefaultCell>900</DefaultCell>,
      onlinePlaces: <DefaultCell>300</DefaultCell>,
      offlinePlaces: <DefaultCell>500</DefaultCell>,
      sumOfPrice: <DefaultCell>4200 MAD</DefaultCell>,
    },
    {
      product: <ProductCell image={blackChair} name=" IMGHRANE GUEST : NAJAT AATABOU - HAJIB - LAARBI IMGHRANE - HAMID EL KASRI " place={"théâtre de verdure"} />,
      value: <DefaultCell>54</DefaultCell>,
      adsSpent: <DefaultCell>14</DefaultCell>,
      refunds: <DefaultCell>500</DefaultCell>,
      invitationPlaces: <DefaultCell>26</DefaultCell>,
      onlinePlaces: <DefaultCell>100</DefaultCell>,
      offlinePlaces: <DefaultCell>50</DefaultCell>,
      sumOfPrice: <DefaultCell>1500 MAD</DefaultCell>,
    },
    {
      product: <ProductCell image={wirelessCharger} name=" BOUDCHART " place={"théâtre de verdure"} />,
      value: <DefaultCell>57</DefaultCell>,
      adsSpent: <DefaultCell>64</DefaultCell>,
      refunds: <DefaultCell>500</DefaultCell>,
      invitationPlaces: <DefaultCell>50</DefaultCell>,
      onlinePlaces: <DefaultCell>900</DefaultCell>,
      offlinePlaces: <DefaultCell>60</DefaultCell>,
      sumOfPrice: <DefaultCell>450 MAD</DefaultCell>,
    },
    {
      product: <ProductCell image={tripKit} name=" Soirée AJYAL 'IZENZAREN, IMGHRAN, JUBANTUJA' " place={"théâtre de verdure"} />,
      value: <DefaultCell>25</DefaultCell>,
      adsSpent: <DefaultCell>31</DefaultCell>,
      refunds: <DefaultCell>500</DefaultCell>,
      invitationPlaces: <DefaultCell>5700</DefaultCell>,
      onlinePlaces: <DefaultCell>540</DefaultCell>,
      offlinePlaces: <DefaultCell>800</DefaultCell>,
      sumOfPrice: <DefaultCell>980 MAD</DefaultCell>,
    },
  ],
};

export default dataTableData;
