/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import Swal from "sweetalert2";

import {
  SpeedDial,
  SpeedDialAction
} from "@mui/material";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/More";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import InfoIcon from '@mui/icons-material/Info';

// Data
import dataTableData from "./../../../layouts/events/event-page/data/dataTableData";
import { getAllMediaApi, deleteMediaApi, mediaStatusApi, mediaSliderStatusApi, mediaWebSiteStatusApi } from "api/medias";
import { useNavigate } from "react-router-dom";

function MediaList() {
  const navigate = useNavigate()
  const [menu, setMenu] = useState(null);

  const [tableRowData, setTableRowData] = useState([])

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    getTableData()
  }, [])

  const getTableData = async () => {
    try {
      const tableData = await getAllMediaApi()
      setTableRowData(tableData)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleDeleteMedia = async (ID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment supprimer cette événement",
        icon: 'warning',
        confirmButtonText: 'Supprimer'
    }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteMediaApi(ID)
            getTableData()

            Swal.fire(
              'Supprimée',
              '',
              'success'
            )
          }
          catch (err) {
            console.log(err)
          }
        }
    });
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleChangeMediaStatus = async (ID, status) => {
    try {
      await mediaStatusApi(ID, status)
      getTableData()
      Swal.fire(
        status ? "Activée" : "Desactivée",
        '',
        'success'
      )
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleSliderViewStatus = async (ID, status) => {
    try {
      await mediaSliderStatusApi(ID, status)
      getTableData()
      Swal.fire(
        status ? "Activée" : "Desactivée",
        '',
        'success'
      )
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleWebViewStatus = async (ID, status) => {
    try {
      await mediaWebSiteStatusApi(ID, status)
      getTableData()
      Swal.fire(
        status ? "Activée" : "Desactivée",
        '',
        'success'
      )
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleMediaEditeOpen  = (mediaID) => {
    navigate(`/media/modifier/${mediaID}`);
  }

  const handleCopyClick = () => {
    try {

    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          {/* <MDButton variant="gradient" color="info">
            Ajouter un événement
          </MDButton> */}
          {/* <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;par type d&apos;événement
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox> */}
        </MDBox>
        <Card>
          {/* <DataTable table={tableRowData} entriesPerPage={false} canSearch /> */}

          <TableContainer component={Paper}>
              <MDBox width="15rem" ml="auto" display="flex" justifyContent="right" padding={2}>
                {/* <MDInput
                  placeholder="Search by name..."
                  size="small"
                  fullWidth
                  // value={search}
                  // onChange={({ currentTarget }) => {
                  //   setSearch(search);
                  //   onSearchChange(currentTarget.value);
                  // }}
                /> */}
              </MDBox>
              <Table aria-label="simple table">
                <TableHead style={{ display: "table-header-group", important: "true" }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Carrousel
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Site web
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Nom
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Statut
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRowData.map((el, index) => (
                    <TableRow
                      key="index"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          maxWidth: 100,
                        }}
                      >
                       <Switch checked={el.showAtSlider} onChange={() => handleSliderViewStatus(el._id, !el.showAtSlider)} /> 
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          maxWidth: 100,
                        }}
                      >
                       <Switch checked={el.showAtWebSite} onChange={() => handleWebViewStatus(el._id, !el.showAtWebSite)} /> 
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          maxWidth: 100,
                        }}
                      >
                        <p>{el.mediaName}</p>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          maxWidth: 100,
                        }}
                      >
                        {/* {
                          el.eventStatus === "N" ? <StatusCell icon="replay" color="warning" status="publiée" /> :
                          el.eventStatus === "V" ? <StatusCell icon="done" color="success" status="inactive" /> :
                          el.eventStatus === "A" ? <StatusCell icon="close" color="error" status="inactive" /> :
                          el.eventStatus === "F" ? <StatusCell icon="close" color="error" status="inactive" /> : <></>
                        } */}
                        {
                          el.isActive ? <StatusCell icon="done" color="success" status="publiée" />
                          : <StatusCell icon="close" color="error" status="pas publiée" />
                        }
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          maxWidth: 100,
                        }}
                      >
                        <Box sx={{ height: 80, transform: "translateZ(0px)" }}>
                          <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{ position: "absolute", top: 0, right: "60%" }}
                            className="custom-speed-dial"
                            icon={<MoreIcon />}
                            direction="left"
                          >
                            {
                              !el.isActive ?
                              <SpeedDialAction
                                key={""}
                                icon={<PlayCircleFilledIcon />}
                                tooltipTitle="Activer"
                                onClick={() => handleChangeMediaStatus(el._id, true)}
                              /> :
                              <SpeedDialAction
                                key={""}
                                icon={<StopCircleIcon />}
                                tooltipTitle="Desactiver"
                                onClick={() => handleChangeMediaStatus(el._id, false)}
                              />
                            }
                            <SpeedDialAction
                              key={""}
                              icon={<EditIcon />}
                              tooltipTitle="Modifier"
                              onClick={() => handleMediaEditeOpen(el._id)}
                            />
                            <SpeedDialAction
                              key={""}
                              icon={<DeleteIcon />}
                              tooltipTitle="Supprimer"
                              onClick={() => handleDeleteMedia(el._id)}
                            />
                          </SpeedDial>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MediaList;
