import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import { useMaterialUIController } from "context";

function MDEditor({ value, defaultValue }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // Ref to store the initial defaultValue
  const defaultValueRef = useRef(defaultValue);

  const initialEditorState = useMemo(() => {
    if (defaultValue) {
      const blocksFromHTML = convertFromHTML(defaultValue);
      if (blocksFromHTML && blocksFromHTML.contentBlocks) {
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        return EditorState.createWithContent(contentState);
      }
    }
    return EditorState.createEmpty();
  }, [defaultValue]);

  const [editorState, setEditorState] = useState(initialEditorState);
  const [convertedContent, setConvertedContent] = useState(convertToHTML(initialEditorState.getCurrentContent()));

  useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  useEffect(() => {
    // Update editor state only if defaultValue changes
    if (defaultValue !== defaultValueRef.current) {
      defaultValueRef.current = defaultValue; // Update the ref
      const newEditorState = () => {
        if (defaultValue) {
          const blocksFromHTML = convertFromHTML(defaultValue);
          if (blocksFromHTML && blocksFromHTML.contentBlocks) {
            const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
            return EditorState.createWithContent(contentState);
          }
        }
        return EditorState.createEmpty();
      };
      setEditorState(newEditorState());
    }
  }, [defaultValue]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    const html = convertToHTML(state.getCurrentContent());
    setConvertedContent(html);
    if (value && typeof value === "function") {
      value(html);
    }
  };

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      <Editor editorState={editorState} onEditorStateChange={handleEditorChange} />
    </MDEditorRoot>
  );
}

MDEditor.propTypes = {
  value: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default MDEditor;
