/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Swal from "sweetalert2";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewEvent page components
import FormFields from "./components/FormFields";
import { addNewMediaImgsApi } from "api/medias";

import { useNavigate } from "react-router-dom";

function NewProducteur() {
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    info: {},
  });

  // useEffect(() => {
  //   console.log("formValue changed:", formValue);
  // }, [formValue]);

  const handleAddNewEvent = async () => {
    try {
      const eventObject = {
        mediaName: formValue?.eventInfo?.eventTitle || "",
        mediaVideo: formValue?.eventInfo?.promoVideo || "",
        mediaDescription: formValue?.description || "",
        mediaImg: formValue.eventMainImg || null
      }

      await addNewMediaImgsApi(eventObject)

      Swal.fire({
        title: "Ajoutée",
        text: "",
        icon: "success"
      });

      navigate("/coupons/ajouter");
    } catch (err) {
      Swal.fire({
        title: err.response.data.error,
        text: "",
        icon: "error"
      });
    }
  };

  const handleGetFormValues = (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        eventInfo: value,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Ajouter un producteur
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text"></MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="info" onClick={handleAddNewEvent}>
                  Ajouter
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormFields formValues={handleGetFormValues} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewProducteur;
