/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import Swal from "sweetalert2";

import { SpeedDial, SpeedDialAction } from "@mui/material";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/More";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from '@mui/icons-material/Info';

// Data
import { getAllEventMessagesApi, deleteEventMessageApi } from "api/messages";
import EventPlaces from "layouts/events/new-event/modals/Places";

function MessagesList() {
  const [menu, setMenu] = useState(null);
  const [tableRowData, setTableRowData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalItemData, setOpenModalItemData] = useState("");

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    getTableData();
  }, []);

  const handleCloseModal = (params) => {
    setOpenModal(false);
  };

  const handleEditeModal = (Data) => {
    setOpenModalItemData(Data);
    setOpenModal(true);
  };

  const getTableData = async () => {
    try {
      const tableData = await getAllEventMessagesApi();
      console.log(tableData)
      setTableRowData(tableData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteItem = async (ID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment supprimer",
        icon: "warning",
        confirmButtonText: "Supprimer",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteEventMessageApi(ID);
            getTableData();

            Swal.fire("Supprimée", "", "success");
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshData = () => {
    getTableData();
  };

  return (
    <DashboardLayout>
      <EventPlaces
        openEventPlace={openModal}
        closeEventPlace={handleCloseModal}
        refreshData={handleRefreshData}
        isEdite={true}
        openModalItemData={openModalItemData}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}></MDBox>
        <Card>
          <TableContainer component={Paper}>
            <MDBox
              width="15rem"
              ml="auto"
              display="flex"
              justifyContent="right"
              padding={2}
            ></MDBox>
            <Table aria-label="simple table">
              <TableHead style={{ display: "table-header-group", important: "true" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Type de l'événemment
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Périoude
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Téléphone
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Société
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Project
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRowData.map((el, index) => (
                  <TableRow key="index" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        maxWidth: 100,
                      }}
                    >
                      <p>
                        {
                          el.typeOfEvent === "conferences" ? "conférences"
                          : el.typeOfEvent === "proRom" ? "salons professionnels"
                          : el.typeOfEvent === "seminaires" ? "séminaires"
                          : el.typeOfEvent === "ceremonies&galas" ? "cérémonies et galas"
                          : el.typeOfEvent === "launchProducts" ? "Lancement de produits"
                          : el.typeOfEvent === "jon&EventRom" ? "Salons de l'emploi et évènements de recrutement"
                          : el.typeOfEvent === "teamBuilding" ? "Team Building"
                          : el.typeOfEvent === "virtualEvent" ? "Évènements virtuels"
                          : el.typeOfEvent === "musicConcerts" ? "Concerts de musique"
                          : el.typeOfEvent === "other" ? "Autres…"
                          : ""
                        }
                      </p>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        maxWidth: 100,
                      }}
                    >
                      <p>
                        {
                          el.selectedDate === "quickly" ? "Rapidement"
                          : el.selectedDate === "forThisMonth" ? "Pour ce mois-ci"
                          : el.selectedDate === "inTheQuarter" ? "Dans le trimestre"
                          : el.selectedDate === "currentOfYear" ? "Courant d'année"
                          : el.selectedDate === "noVisibility" ? "Nous n'avons pas encore de visibilité"
                          : ""
                        }
                      </p>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        maxWidth: 100,
                      }}
                    >
                      <p>{el.email}</p>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        maxWidth: 100,
                      }}
                    >
                      <p>{el.phone}</p>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        maxWidth: 100,
                      }}
                    >
                      <p>{el.companyInfo}</p>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        maxWidth: 100,
                      }}
                    >
                      <p>{el.projectInfo}</p>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        maxWidth: 100,
                      }}
                    >
                      <Box sx={{ height: 80, transform: "translateZ(0px)" }}>
                        <SpeedDial
                          ariaLabel="SpeedDial basic example"
                          sx={{ position: "absolute", top: 0, right: "60%" }}
                          className="custom-speed-dial"
                          icon={<MoreIcon />}
                          direction="left"
                        >
                          {/* <SpeedDialAction
                            key={""}
                            icon={<InfoIcon />}
                            tooltipTitle="details"
                            onClick={() => handleEditeModal(el)}
                          /> */}
                          <SpeedDialAction
                            key={""}
                            icon={<DeleteIcon />}
                            tooltipTitle="Supprimer"
                            onClick={() => handleDeleteItem(el._id)}
                          />
                        </SpeedDial>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MessagesList;
