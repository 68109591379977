/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sales dashboard components
import DefaultCell from "layouts/events/event-details/components/DefaultCell";

const dataTableData2 = {
  columns: [
    { Header: "Revendeurs", accessor: "siege" },
    { Header: "Tickets vendus", accessor: "ticketsVendus", align: "center" },
    { Header: "Prix unitaire", accessor: "unitPrice", align: "center" },
    { Header: "Prix total", accessor: "sumOfPrice", align: "center" },
  ],

  rows: [
    {
      siege: <DefaultCell>Persone 1 (En ligne)</DefaultCell>,
      ticketsVendus: <DefaultCell></DefaultCell>,
      unitPrice: <DefaultCell></DefaultCell>,
      sumOfPrice: <DefaultCell></DefaultCell>,
    },
    {
      siege: <DefaultCell>Persone 2 (En ligne)</DefaultCell>,
      ticketsVendus: <DefaultCell></DefaultCell>,
      unitPrice: <DefaultCell></DefaultCell>,
      sumOfPrice: <DefaultCell></DefaultCell>,
    },
    {
      siege: <DefaultCell>Persone 3 (Hors ligne)</DefaultCell>,
      ticketsVendus: <DefaultCell></DefaultCell>,
      unitPrice: <DefaultCell></DefaultCell>,
      sumOfPrice: <DefaultCell></DefaultCell>,
    },
    {
      siege: <DefaultCell>Total</DefaultCell>,
      ticketsVendus: <DefaultCell></DefaultCell>,
      unitPrice: <DefaultCell></DefaultCell>,
      sumOfPrice: <DefaultCell></DefaultCell>,
    },
  ],
};

export default dataTableData2;
